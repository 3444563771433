/* You can add global styles to this file, and also import other style files */

//@import url("assets/bootstrap/purple-green.css");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeng/resources/themes/nova/theme.css";
@import "../node_modules/primeflex/primeflex.min.css";
@import "../node_modules/primeng/resources/primeng.min.css";

@import "../node_modules/@corteva-research/ngx-components-core/assets/styles/research-web-components.scss";
@import "../node_modules/@corteva-research/ngx-components-core/assets/fonts/fonts.scss";

@import '../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';

.nav-group-content-wrapper {
    display : block;
    position : absolute;
    background-color : #fff;
    left : initial !important ;
    right : initial !important ;
    border-bottom : 0 solid #e6e8ea !important ;
    transition : height 0.2s ease-in-out;
    z-index : 1000;
    overflow : hidden;
    // height : 151px !important ;
}

.resShadow {
    border-radius : 5px !important ;
    border : rgba(0, 0, 0, 0.15) solid 1px;
    box-shadow : 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    background-color : white;
    color: black;
    font-size : 12px;
}

.hover .resShadow:hover {
        color : #1a2326;
        text-decoration : none;
        border : #2b85e7 solid 1px;
        border-radius : 4px;
        box-shadow : 4px 8px 8px rgba(1, 67, 163, 0.24), 0 0 8px rgba(1, 67, 163, 0.12), 0 6px 18px rgba(43, 133, 231, 0.12);
}

.resShadow-1 {
    border-top : 1px solid rgba(0, 0, 0, 0.15);
    background-color : white;
    font-size : 12px;
    margin:.5em;
}

.hover .resShadow-1:hover {
    color : #1a2326;
    text-decoration : none;
    border : #2b85e7 solid 1px;
    border-radius : 4px;
    box-shadow : 4px 8px 8px rgba(1, 67, 163, 0.24), 0 0 8px rgba(1, 67, 163, 0.12), 0 6px 18px rgba(43, 133, 231, 0.12);
}

body .ui-dataview .ui-dataview-header {
    background-color : initial !important ;
    color : #333333;
    border : initial !important ;
    font-weight : 700;
    border-bottom : 0 none;
    padding : 0.571em 0 1em 0 !important ;
}

body .ui-dataview .ui-dataview-content {
    border : initial !important ;
    color : #333333;
    padding : initial !important ;
}

// body .ui-dataview .ui-dataview-content .ui-g-12 {
//     padding-right : initial !important ;
//     padding-left : initial !important ;
// }

body .ui-dataview {
    border : initial !important ;
    box-shadow : none;
}

body .ui-paginator .ui-paginator-first, body .ui-paginator .ui-paginator-prev, body .ui-paginator .ui-paginator-next, body .ui-paginator .ui-paginator-last {
        color: #848484;
        height: 2.286em;
        min-width: 2.286em;
        border: 0 none;
        line-height: 2.286em;
        padding: 0;
        margin: 0;
        vertical-align: top;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0;
        padding-left:10px !important;
        padding-right:10px !important;
}

body .ui-paginator {
    background-color : #f4f4f4;
    border : initial !important ;
    padding : initial !important ;
    border-radius : 5px !important ;
}

body .ui-dataview .ui-dataview-footer {
    border : initial !important ;
}

body .p-button {
    font-size : 12px !important ;
    height: 28px !important;
}

body .ui-fileupload-content .ui-button {
    cursor : pointer;
    background-color : #ef4d5b !important ;
}

body .ui-inputswitch .ui-inputswitch-slider {
    transition : background-color 0.2s;
    border-radius : 30px;
    background : darkgray;
}
body .p-widget {
    font-family : "Open Sans", "Helvetica Neue", sans-serif;
    font-size : 12px !important ;
    text-decoration : none;
}
body .p-accordion .p-accordion-content {
    padding : initial !important ;
    border : initial !important ;
    background-color : #ffffff;
    color : #212529;
}
body .p-accordion .p-accordion-header:not(.p-state-disabled).p-state-active a {
    background-color : #d0e9ff !important ;
    border : #a0d3ff solid 1px !important ;
    color : black !important ;
}
body .p-accordion .p-accordion-header:not(.p-state-disabled).p-state-active a:hover {
    background-color : #d0e9ff !important ;
    border : #a0d3ff solid 1px !important ;
    color : black !important ;
}
body .p-accordion .p-accordion-header:not(.p-state-disabled).p-state-active a .p-accordion-toggle-icon {
    color : black !important ;
}

.p-calendar-inputStyle input {
    width : 80px !important ;
}

.envName {
    font-size : 40% !important ;
    background : #dc3545 !important ;
    color : white !important ;
    vertical-align : sub !important ;
    padding-left : 5px;
    padding-right : 5px;
    margin-left: -12px;
}

.unauthorized .ui-dialog .ui-dialog-titlebar {
    background-color : maroon;
    color : white;
    width : 100%;
    box-shadow : 10px 10px 5px #aaaaaa;
}

.p-dialog .p-dialog-content {
    background: #ffffff;
    color: #495057;
    padding: 2rem 1.5rem 2rem 1.5rem !important;
}

.unauthorized .p-dialog .p-dialog-content {
    font-size : 13px;
    color : black;
    padding-left : 45px;
    padding-top : 20px;
    box-shadow : 10px 10px 5px #aaaaaa;
    text-align : left;
}

// .moreSynonyms {
//     margin-top : 35px;
// }

body .showAll {
    background-color : white !important ;
    color : blue !important ;
    border : none !important ;
    float : left;
    margin-top : -4px;
}

.showAll .ui-button-text {
    padding-left : 0 !important ;
}

.showAll .p-button-icon-right {
    top : 54%;
    font-size : 1.1em;
}

.showAll .ui-button:enabled:hover {
    background-color : white !important ;
    color : black !important ;
}

body .hideAll {
    background-color : white !important ;
    color : blue !important ;
    border : none !important ;
    // float : left;
    margin-top : -4px;
}

.hideAll .p-button-icon-right {
    top : 54%;
    font-size : 1.1em;
}

.hideAll .ui-button:enabled:hover {
    background-color : white !important ;
    color : black !important ;
}

.firstSynonym {
    float : left;
    padding-left : 5px;
}
   
.request .ui-dropdown-label {
    padding-top : 0 !important ;
}
    
.request .ui-button-icon-only .ui-button-text, .ui-button-text-empty .ui-button-text {
    padding : 0.15em !important ;
}
    
.request .mat-step-header.cdk-program-focused, .mat-step-header:hover {
    background-color : #83aba863;
}
    
.request .mat-horizontal-stepper-header-container {
    background-color : #d0e9ff;
}

.request .mat-horizontal-stepper-header {
    height : 40px !important ;
}

.mat-step-header .mat-step-icon-selected {
    background-color : #2b85e7;
}

.request .mat-step-label-selected {
    font-weight : bold;
    background-color : #2b85e7;
    color : white !important ;
    padding : 4px 20px;
    border-radius : 3px;
}

.request .p-inputtext {
    height : 25px !important ;
    padding-top : 2px !important ;
    //vertical-align: middle;
}



.requester .p-inputtext {
    height: 25px !important;
    padding-top: 5px !important;
    width: 268px;
}

.recipient .p-inputtext {
  width: 206px;
}

.request .p-chkbox .p-chkbox-box {
    width : 15px !important ;
    height : 15px !important ;
}

.request .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
    font-size : 14px !important ;
}
    
.request .ui-chkbox {
    width : 15px !important ;
    height : 15px !important ;
}

.request .ui-dataview.ui-dataview-list > .ui-dataview-content > div.ui-g > div {
    width : 100%;
    padding-bottom : 5px !important ;
}

.request .ui-panel .ui-panel-titlebar {
    background : white !important ;
}
    
.request .ui-g-12 {
    padding-top : 5px !important ;
    padding-bottom : 0 !important ;
}
    
.request .ui-dataview .ui-dataview-header {
    padding-top : 0 !important ;
    padding-bottom : 0 !important ;
}    
    
.messages .ui-messages-icon {
    display : none !important ;
}

.request .ui-messages-close {
    font-size : 1.1em !important ;
}

.request .ui-messages {
    margin : 0.25em;
}

.ui-messages .ui-messages-summary {
    font-weight : bold;
    margin-left : 0 !important ;
}

.ui-messages.ui-messages-error {
    background-color : #f5d9db !important ;
    border : none 0;
    color : #212121;
}

.request .ui-messages.ui-messages-info {
    background-color : #d0e5f5 !important ;
    border : none 0;
    color : #212121;
    padding-top : 0.25em !important ;
    padding-bottom : 0.25em !important ;
    padding-left : 1em;
    padding-right : 1em;
}
    
.p-dialog-header {
    background-color : #dbdbdb !important;
    padding: 1em !important;
}

.p-dialog .p-dialog-footer {
    background: #dbdbdb !important;
    color: #495057;
    padding: 0.571em 1em !important;
}
.form-group {
    margin-bottom: unset!important;
}
    
.dialog .ui-dialog-titlebar-close {
    display : none;
}
 
.request .select-button .p-button-text {
    width : 45px;
}
    
.p-dropdown-label {
    font-size : 12px !important ;
}

.dialog .p-dialog {
    box-shadow : 0 3px 3px rgba(8, 8, 8, 0.72), 0 0 8px rgba(0, 0, 0, 0.71), 0 6px 18px rgba(4, 4, 4, 0.38);
}

.invalid-feedback {
    display : block;
}

.messages .p-messages ul {
    display : block !important ;
    font-size : 10px;
    width : 100% !important ;
}

.address .ui-g-12 {
    padding-top : 2px !important ;
    padding-bottom : 2px !important ;
}

.mat-step-label-selected {
    font-size : 14px;
    font-weight : 800;
    color : #2b85e7 !important ;
}

.request .address .p-inputtext {
    font-size : 12px;
    height: 25px;
}

.request .address .p-autocomplete-multiple-container li {
    width : 100% !important ;
}
.request .address .p-autocomplete-input-token input {
    width : 100% !important ;
    font-size : 12px !important ;
}

.formStyle .is-invalid {
    background-image : initial !important ;
}

.errorColor {
    color : #dc3545;
    font-size: 12px;
}

.carousel .p-carousel-items-container .p-carousel-item {
    width : 100%;
    box-sizing : border-box;
    flex : 0 0 33.33% !important ;
}

.carousel .p-carousel-items-content {
    overflow : hidden;
    width : 100%;
    background-color : whitesmoke;
}

.recipient-carousel .p-carousel-items-container .p-carousel-item {
    width : 100%;
    box-sizing : border-box;
    flex : 0 0 25% !important ;
}

.header-bar {
    background-color : #e6e6e657;
    vertical-align : middle;
    font-weight : bold;
}

.spacing {
    margin-bottom : 1em;
}

.containerLabel .p-dropdown-label
{
    width : 420px;
    height: 25px;
}
    
.containerLabel .p-dropdown-panel .p-dropdown-item {
    font-size : 12px !important ;
}
.recipientProfileDialog .p-dialog-titlebar-icon {
    display : none !important ;
}

.recipientProfileDialog .p-inputtext {
    height : 25px !important ;
    font-size : 12px !important ;
    border-radius : 5px !important ;
    width : 230px;
}

.recipientProfileDialog .p-dialog .p-dialog-footer {
    background-color : #dbdbdb;
}

.recipientProfileDialog .ui-dropdown .ui-dropdown-label {
    padding-top : 2px !important ;
}
.addressDialog .p-dialog {
    box-shadow : 0 3px 3px rgba(8, 8, 8, 0.72), 0 0 8px rgba(0, 0, 0, 0.71), 0 6px 18px rgba(4, 4, 4, 0.38);
}

.addressDialog .p-dialog-titlebar-close {
    display : none;
}

.addressDialog .p-dialog .p-dialog-footer {
    background-color : #dbdbdb;
}


.copy-request-dialog .p-dialog .p-dialog-footer {
    background-color : #dbdbdb;
}

.copy-request-dialog .ui-dialog-titlebar-close {
    display : none;
}

.copy-request-dialog .p-inputtext {
    height : 25px !important ;
    font-size : 12px !important ;
    border-radius : 5px !important ;
}


.dropdownCSS .form-control {
    padding : initial !important ;
    height : 0% !important ;
    border : #a6a6a6 solid 1px !important ;
    margin-top:1.5em;
}
.dropdownCSS .ui-dropdown {
    border : initial !important ;
}
.dropdownCSS .is-invalid {
    border-color : #dc3545 !important ;
}
.dropdownCSS .is-invalid .p-dropdown-label {
    color : #dc3545 !important ;
}
.dropdownCSS .is-invalid .p-dropdown-trigger {
    color : #dc3545 !important ;
}
.dropdownCSS .ui-dropdown-label {
    padding-top : 5px !important ;
}

.recipientProfile .p-inputtext {
    height : 25px !important ;
    font-size : 12px !important ;
    border-radius : 5px !important ;
    padding-top : 2px !important ;
}

.advanced-search .p-dropdown .p-dropdown-label
{
    height: 23px !important;
    padding-top: 0px !important;
    /* font-style: italic !important; */
    font-size: 10px !important; 
    width: 122px!important;
}

.advanced-search .p-dropdown
{
    min-width: 12.5em !important;
    border-color: lightblue !important; 
}

.advanced-search .p-multiselect
{
    border-color: lightblue !important;
}

.advanced-search .p-multiselect .p-multiselect-label {
    height: 23px !important;
    padding-top: 0px !important;
    font-size: 10px !important;
    width: 122px!important;
    color: black !important;
}


.advanced-search .p-multiselect-defalut-label-font {
    font-style: italic !important;
}

.advanced-search .p-multiselect {
 /* width: 200px !important; */
    min-width: 12.5em !important;
    height: 26px!important;
}

.advanced-search .p-inputtext {
    border-radius:3px !important;
    border-right-width: 1px !important;
    border-right-style: solid !important;
    // width: 150px !important;
}

.mandatory-field
{
    color: #c01120;
    font-weight: bold;
}

.p-placeholder {
    font-style: normal !important;
    font-size: 12px !important; 
}

.search-button .ui-button-text
{
margin-top:-3px;
}

.userDialog .ui-dialog-titlebar-icon {
    display : none !important ;
}

.userDialog .p-inputtext {
    height : 25px !important ;
    font-size : 12px !important ;
    border-radius : 5px !important ;
    width : 210px;
}

.userDialog .p-dialog .p-dialog-footer {
    background-color : #dbdbdb;
}

.userDialog .ui-dropdown .ui-dropdown-label {
    padding-top : 2px !important ;
}

.userDialog .p-dropdown-label {
    width:177px !important;
    padding-top: 0px !important;
}

.recipientProfileDialog .p-dropdown-label {
    width: 195px !important;
    padding-top: 0px !important;
}

.ui-fieldset, .ui-fieldset .ui-fieldset-legend {
    padding: .5em .5em !important;
    width: unset !important;
}

.search {
    background-color: white !important;
    padding: unset !important;
}

body .ui-dropdown .ui-dropdown-label {
    padding-right: 3em !important;
}

// .col-3 {
//     width: unset !important;
// }
.p-component
{
    font-size:12px !important;
}

.p-dataview .p-dataview-header {
    background: white !important;
    color: #495057;
    border: none !important;
    border-width: 1px 0 1px 0;
    padding: unset !important;
    font-weight: 600;
}

.p-dialog-header {
    display: -ms-flexbox;
    justify-content: space-between;
}

.addressDialog .p-dropdown-label {
    width: 111px !important;
}

.add-sample-priority .p-radiobutton .p-radiobutton-box
{
    width: 15px !important ;
    height: 15px !important ;
}

.add-sample-priority .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 8px !important;
    height: 8px !important;
}

.documentCheckboxes .p-checkbox .p-checkbox-box
{
    width: 15px !important ;
    height: 15px !important ;
}

.documentCheckboxes .p-checkbox .p-checkbox-box .p-checkbox-icon
{
    font-size:10px !important;
}

.cursor-pointer
{
    cursor: pointer;
}

.p-datatable-resizable .p-datatable-tbody>tr>td, .p-datatable-resizable .p-datatable-tfoot>tr>td, .p-datatable-resizable .p-datatable-thead>tr>td {
    overflow: hidden;
    white-space: normal !important;
}

.p-inputgroup {
    width: unset !important;
}

.p-button:disabled {
    cursor: not-allowed !important;
}

.delegate .p-dropdown-label{
    width:120px;
    height:28px;
}

.delegate .p-inputtext{
    padding-top:1px !important;
}

.needByDate .p-inputtext {
    width : 96px !important ;
    font-size : 12px !important ;
}

.needByDate .p-calendar .p-datepicker {
    min-width : 70% !important ;
    top : -50px !important ;
    left : -190px !important;
}
    
.needByDate .p-datepicker table {
    font-size : 10px !important ;
}
    
.needByDate .p-datepicker table td {
    padding : initial !important ;
}

.needByDate .p-datepicker td > span {
    padding : initial !important ;
}

.needByDate .p-datepicker .p-datepicker-header .p-datepicker-title {
    font-size : 12px !important ;
}

.shipNowCheckbox .p-checkbox .p-checkbox-box
{
    width: 15px !important ;
    height: 15px !important ;
}

.shipNowCheckbox .p-checkbox .p-checkbox-box .p-checkbox-icon
{
    font-size:10px !important;
}

.logoStyle{
    height: 55px !important;
    margin-top: -8px;
}

.p-component {
    font-size: 0.875rem !important;
}

.p-datepicker {
    min-width: 350px !important;
    padding: unset !important;
}
.p-datepicker table  {
    margin: unset !important;
}
.p-datepicker table td>span {
    width: unset !important;
    height: unset !important;
    font-size: 12px !important;
}

.p-toast-top-right {
    top: 95px !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 0.1rem 0.857rem !important;
    font-size: 12px !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    padding: 0.1rem 0.857rem !important;
    font-size: 12px !important;
}

.p-datatable-resizable-table>.p-datatable-thead>tr>th, .p-datatable-resizable-table>.p-datatable-tfoot>tr>td, .p-datatable-resizable-table>.p-datatable-tbody>tr>td {
    white-space: unset !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
}

.p-datatable-resizable-table>.p-datatable-thead>tr>th, .p-datatable-resizable-table>.p-datatable-tfoot>tr>td, .p-datatable-resizable-table>.p-datatable-tbody>tr>td {
    white-space: unset !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
}

a:not([href]):not([class]) {
    color: black !important;
}